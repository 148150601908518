import React from 'react'

import Box from '../Box'
import Flex from '../Flex'
import Button from '../Button'
import BackgroundImage from '../BackgroundImage'
import { responsive } from '../../contexts/responsive'

import Colon from '../Logos/Colon'

import fight from './fight.svg'

const links = [
  { label: '看實驗計畫', to: '/#guide', trackerName: '_cta_guide', },
  { label: '找設計靈感', to: '/portfolio', trackerName: '_cta_collection' },
  { label: '看主題特輯', to: '/articles', trackerName: '_cta_article' },
]

const homepagelinks = [
  { label: '來找我們合作', to: '/contact', trackerName: '_cta_collab' },
  { label: '找資訊設計靈感', to: '/portfolio', trackerName: '_cta_collection' },
  { label: '看合作產業分布', to: '/about/#industry', trackerName: '_cta_about' },
]

const postlinks = [
  { label: '看實驗計畫', to: '/#guide', trackerName: 'collection_cta_guide' },
  { label: '和我們合作', to: '/contact', trackerName: 'collection_cta_collab' },
  { label: '看主題特輯', to: '/articles', trackerName: 'collection_cta_article' },
]

const ButtonModule = ({ label, to, index, fontSize, trackerName, ...props }) => (
  <Box width={responsive('100%', 1 / 3)} px={responsive(0, '1.75em')} mt={responsive(index && '1.25em', 0)} {...props}>
    <Button.Outline trackerName={trackerName} fontSize={responsive('1em', '2.125em')} width="100%" py="0.875rem" to={to}>
      {label}
    </Button.Outline>
  </Box>
)

const SeeMore = ({ page, ...props }) => {
  // console.log(page)
  return (
    <Flex flexDirection={'column'} {...props}>
      <Colon width={responsive('1.25em', '1.375em')} mx="auto" mb={responsive('3.5em', '15.375em')} />
      <Box.Relative flex={1} bg="custom.filterBg" pt={responsive('3em', '9.25em')} pb={responsive('4.875em', '6em')} px={responsive(page === 'homepage' ? '3.25em' : '5.25em', page === 'homepage' ? '5em' : '13.5em')}>
        <Box mx="auto" width={responsive('50%', '17.8%')} position={responsive('relative', 'absolute')} left={responsive('unset', '50%')} top="0" transform={responsive('none', 'translate(-50%, -50%)')}>
          <BackgroundImage src={fight} ratio={282.88 / 192} />
        </Box>
        <Flex maxWidth={responsive('30em', '91.75em')} flexDirection={responsive('column', 'row')} pt={responsive('1.25em', 0)} mx="auto" borderTop={responsive('1.5px solid black', 'none')}>
          {(page === 'blog' || page === 'guide') ? homepagelinks.map((d, i) => (
            <ButtonModule {...d} trackerName={`${page + d.trackerName}`} index={i} key={i} />
          )) : page === 'collection' ? postlinks.map((d, i) => (
            <ButtonModule  {...d} index={i} key={i} />
          )) : links.map((d, i) => (
            <ButtonModule  {...d} trackerName={`${page + d.trackerName}`} index={i} key={i} />
          ))}
        </Flex>
      </Box.Relative>
    </Flex>
  )
}

export default SeeMore
