import React from 'react'
import SVG from '../SVG'

const Colon = (props) => {
  return (
    <SVG viewBox="0 0 66 136" {...props}>
      <g fill="currentColor">
        <path d="M33,81a22.21,22.21,0,1,0,22.2,22.2A22.2,22.2,0,0,0,33,81Zm0,35.19a13,13,0,1,1,13-13A13,13,0,0,1,33,116.19Z"/>
        <circle cx="33.02" cy="38.6" r="22.2"/>
      </g>
    </SVG>
  )
}

export default Colon
