import React, { useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { Link } from '@chakra-ui/react'
import { navigate } from 'gatsby'

import Box from '../../components/Box'
import Text from '../../components/Text'
import SeeMore from '../../components/SeeMore'
import Container from '../../components/Container'
import { responsive } from '../../contexts/responsive';
import BackgroundImage from '../../components/BackgroundImage'

import unsub from './unsub.svg'

const UnSubscriptionPage = () => {
  const [value, , remove] = useLocalStorage('email', false);
  useEffect(() => {
    return () => remove()
  }, [])
  if(!value && typeof window !== 'undefined') {
    navigate('/contact')
    return null
  }
  return (
    <Box textAlign={'center'}>
      <Container pt={responsive('3.625em', '6.5em')} pb={responsive('2.875em', '4.375em')}>
        <Box mx="auto" width={responsive('4.875em', '14.88%')}>
          <BackgroundImage src={unsub} ratio={182.87 / 178}  />
        </Box>
        <Text.Bold fontSize={responsive('1em', '3.75em')}>好可惜！不過沒有關係</Text.Bold>
        <Text mt={responsive('0.75rem', '0.67em')} color="custom.textGray" fontSize={responsive('0.875em', '1.875em')}>
          如有你未來任何其他想法、需求，或是想要重新訂閱，<br />
          歡迎發信到<Link fontWeight="bold" textDecoration="underline" href="mailto:hi@relab.cc"> hi@relab.cc </Link>聯絡我們<br />
          在等待聯繫的期間，不妨點擊下方按鈕，繼續探索 RE:LAB 吧！<br />
        </Text>
      </Container>
      <SeeMore page="collab" />
    </Box>
  )
}

export default UnSubscriptionPage
